// import { Spin } from "antd"
import React from "react"
import { useRoutes } from "react-router-dom"
import ROUTER from "./index"
import { BallTriangle } from "react-loader-spinner"
// ANONYMOUS
const PublicRouters = React.lazy(() => import("src/pages/PublicRouters"))
const SvgViewer = React.lazy(() => import("src/pages/SvgViewer"))
const NotFound = React.lazy(() => import("src/pages/NotFound"))
const Home = React.lazy(() => import("src/pages/ANONYMOUS/Home"))
const LoginPage = React.lazy(() => import("src/pages/ANONYMOUS/LoginPage"))
const RegisterPage = React.lazy(() => import("src/pages/ANONYMOUS/Register"))
const InfoAccount = React.lazy(() => import("src/pages/ANONYMOUS/InfoAccount"))
const DevicePage = React.lazy(() => import("src/pages/ANONYMOUS/Device"))
const InstructionPage = React.lazy(() =>
  import("src/pages/ANONYMOUS/Instructions"),
)
const ServicesHome = React.lazy(() =>
  import("src/pages/ANONYMOUS/ServicesHome"),
)
const PayList = React.lazy(() => import("src/pages/ANONYMOUS/PayList"))
const PageVsafe = React.lazy(() =>
  import("src/pages/ANONYMOUS/PayList/components/PageVsafe"),
)

// USER
const PrivateRoutes = React.lazy(() => import("src/pages/PrivateRoutes"))
const ChangePassword = React.lazy(() => import("src/pages/USER/ChangePassword"))

// ADMIN
const AminRoutes = React.lazy(() => import("src/pages/ADMIN/AminRoutes"))
const HomeAdmin = React.lazy(() => import("src/pages/ADMIN/HomeAdmin"))
const BookUse = React.lazy(() => import("src/pages/ADMIN/BookUse"))
const Role = React.lazy(() => import("src/pages/ADMIN/Role"))

function LazyLoadingComponent({ children }) {
  return (
    <React.Suspense
      fallback={
        <div className="loading-center" style={{ height: "100vh" }}>
          {/* <Spin /> */}
          <BallTriangle
            height={50}
            width={50}
            radius={5}
            color="#01638d"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
          />
        </div>
      }
    >
      {children}
    </React.Suspense>
  )
}

const routes = [
  {
    path: ROUTER.SVG_VIEWER,
    element: (
      <LazyLoadingComponent>
        <SvgViewer />
      </LazyLoadingComponent>
    ),
  },
  // ADMIN
  //  USER
  {
    element: (
      <LazyLoadingComponent>
        <PrivateRoutes />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.DOI_MAT_KHAU,
        element: (
          <LazyLoadingComponent>
            <ChangePassword />
          </LazyLoadingComponent>
        ),
      },
    ],
  },

  //  ANONYMOUS
  {
    element: (
      <LazyLoadingComponent>
        <PublicRouters />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.HOME,
        element: (
          <LazyLoadingComponent>
            <Home />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.VSAFE,
        element: (
          <LazyLoadingComponent>
            <PageVsafe />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.SERVICE_INFO,
        element: (
          <LazyLoadingComponent>
            <ServicesHome />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HOME_ID,
        element: (
          <LazyLoadingComponent>
            <Home />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANG_NHAP,
        element: (
          <LazyLoadingComponent>
            <LoginPage />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANG_KY,
        element: (
          <LazyLoadingComponent>
            <RegisterPage />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.ACCOUNT,
        element: (
          <LazyLoadingComponent>
            <InfoAccount />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.QL_THIET_BI,
        element: (
          <LazyLoadingComponent>
            <DevicePage />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.QL_THANH_TOAN,
        element: (
          <LazyLoadingComponent>
            <PayList />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HUONG_DAN,
        element: (
          <LazyLoadingComponent>
            <InstructionPage />
          </LazyLoadingComponent>
        ),
      },
    ],
  },
  // {
  //   path: "*",
  //   element: (
  //     <LazyLoadingComponent>
  //       <NotFound />
  //     </LazyLoadingComponent>
  //   ),
  // },
]
const AppRouter = () => {
  const renderRouter = useRoutes(routes)
  return renderRouter
}
export default AppRouter
